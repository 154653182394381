import React from "react";
// Chakra imports
import {
  Table,
  Textarea,
  Stack,
  Spacer,
  Box,
  Center,
  Tbody,
  Input,
  Text,
  Th,
  InputGroup,
  InputRightAddon,
  Thead,
  Button,
  Tr,
  Icon,
  Td,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import State from 'react-select';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { stateOptions } from "utils"
import axiosInstance from "./../../../axios"

const PatientCreate = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const defaultForm = {
    "name": "",
    "birth_date": "",
    "cellphone1": "",
    "cellphone2": "",
    "city": "",
    "complement": "",
    "cpf": "",
    "district": "",
    "email": "",
    "last_name": "",
    "number": "",
    "rg": "",
    "state": "",
    "street": "",
    "zip_code": ""
  }
  const [patientForm, setPatientForm] = React.useState(defaultForm);
  const [error, setError] = React.useState({})
  const toast = useToast()

  function handleInputPatient(value, name) {
    patientForm[name] = value
    setPatientForm({ ...patientForm })
  }
  const cleanForm = () => {
    setPatientForm(defaultForm)
  }

  function savePatient() {
    setError({})
    axiosInstance.post('patients/', patientForm)
      .then((res) => {
        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth'
        });
        setPatientForm({})
        toast({
          title: 'Sucesso.',
          description: "Paciente salvo com sucesso",
          position: 'top-right',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        cleanForm()
      })
      .catch((err) => {
        setError(err.response.data)
      })
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <Flex >
          <Center>
            <Text fontSize='xl' color={textColor} fontWeight='bold'>
              Cadastro de Pacientes
              </Text>
          </Center>
          <Spacer />
        </Flex>
        <Text fontSize='l' color="gray.400" fontWeight='thin'>
          Informações Pessoais
          </Text>
        <hr />
        <FormControl isInvalid={error?.name}>
          <FormLabel>Nome</FormLabel>
          <Input value={patientForm?.name} onChange={(e) => handleInputPatient(e.target.value, "name")} type='text' id="name" />
          {error.name && <FormErrorMessage>{error.name}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.last_name}>
          <FormLabel>Sobrenome</FormLabel>
          <Input value={patientForm?.last_name} onChange={(e) => handleInputPatient(e.target.value, "last_name")} type='text' id="last_name" />
          {error.last_name && <FormErrorMessage>{error.last_name}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.birth_date}>
          <FormLabel>Data de nascimento</FormLabel>
          <Input value={patientForm.birth_date} onChange={(e) => handleInputPatient(e.target.value, "birth_date")} type='text' id="birth_date" />
          {error.birth_date && <FormErrorMessage>{error.birth_date}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.cpf}>
          <FormLabel>CPF</FormLabel>
          <Input value={patientForm?.cpf} onChange={(e) => handleInputPatient(e.target.value, "cpf")} type='text' id="cpf" />
          {error.cpf && <FormErrorMessage>{error.cpf}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.rg}>
          <FormLabel>RG</FormLabel>
          <Input value={patientForm?.rg} onChange={(e) => handleInputPatient(e.target.value, "rg")} type='text' id="rg" />
          {error.rg && <FormErrorMessage>{error.rg}</FormErrorMessage>}
        </FormControl>
        <Text mt={4} fontSize='l' color="gray.400" fontWeight='thin'>
          Contato
          </Text>
        <hr />
        <FormControl isInvalid={error?.cellphone1}>
          <FormLabel mt={4}>Celular 1</FormLabel>
          <Input value={patientForm?.cellphone1} onChange={(e) => handleInputPatient(e.target.value, "cellphone1")} type='text' id="cellphone1" />
          {error.cellphone1 && <FormErrorMessage>{error.cellphone1}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.cellphone2}>
          <FormLabel>Celular 2</FormLabel>
          <Input value={patientForm?.cellphone2} onChange={(e) => handleInputPatient(e.target.value, "cellphone2")} type='text' id="cellphone2" />
          {error.cellphone2 && <FormErrorMessage>{error.cellphone2}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.email}>
          <FormLabel>Email</FormLabel>
          <Input value={patientForm?.email} onChange={(e) => handleInputPatient(e.target.value, "email")} type='text' id="email" />
          {error.email && <FormErrorMessage>{error.email}</FormErrorMessage>}
        </FormControl>
        <Text mt={4} fontSize='l' color="gray.400" fontWeight='thin'>
          Endereço
          </Text>
        <hr />
        <FormControl isInvalid={error?.street}>
          <FormLabel mt={4}>Lagradouro</FormLabel>
          <Input value={patientForm?.street} onChange={(e) => handleInputPatient(e.target.value, "street")} type='text' id="street" />
          {error.street && <FormErrorMessage>{error.street}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.number}>
          <FormLabel>Numero</FormLabel>
          <Input value={patientForm?.number} onChange={(e) => handleInputPatient(e.target.value, "number")} type='text' id="number" />
          {error.nubmer && <FormErrorMessage>{error.number}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.district}>
          <FormLabel>Bairro</FormLabel>
          <Input value={patientForm?.district} onChange={(e) => handleInputPatient(e.target.value, "district")} type='text' id="district" />
          {error.district && <FormErrorMessage>{error.district}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.city}>
          <FormLabel>Cidade</FormLabel>
          <Input value={patientForm?.city} onChange={(e) => handleInputPatient(e.target.value, "city")} type='text' id="city" />
          {error.city && <FormErrorMessage>{error.city}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.state}>
          <FormLabel>Estado</FormLabel>
          <State
            className="basic-single"
            id="state"
            classNamePrefix="select"
            defaultValue={[{ value: '', label: 'Selecione um estado' }]}
            isSearchable
            onChange={(e) => handleInputPatient(e.value, "state")}
            name="color"
            options={stateOptions}
          />
          {error.state && <FormErrorMessage>{error.state}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.zip_code}>
          <FormLabel mt={4}>Caixa Postal</FormLabel>
          <Input value={patientForm?.zip_code} onChange={(e) => handleInputPatient(e.target.value, "zip_code")} type='text' id="zip_code" />
          {error.zip_code && <FormErrorMessage>{error.zip_code}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={error?.complement}>
          <FormLabel>Complemento </FormLabel>
          <Textarea id="complement"
            value={patientForm?.complement} onChange={(e) => handleInputPatient(e.value, "complement")}
          />
          {error.complement && <FormErrorMessage>{error.complement}</FormErrorMessage>}
        </FormControl>
        <Button mt={4} variant='outline'
          onClick={() => savePatient()}
          colorScheme="blue">Salvar</Button>
      </Card>
    </Flex >
  )
}
export default PatientCreate
