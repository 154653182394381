import React from "react";

import {
  Flex,
  Text,
  Heading,
  Box,
  Spinner,
  Center,
  Stack,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useParams } from 'react-router-dom';
import axiosInstance from "../../../axios";
import { toMoney, convertDate } from "utils"

const ReportDetail = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const [invoice, setInvoice] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();

  React.useEffect(() => {
    axiosInstance.get(`invoice-clinic/${id}`).then((response) => {
      console.log(response.data)
      setInvoice(response.data)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
          <Center>
            <CardHeader mb='20px' style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
              <Text fontSize='lg' color={textColor} fontWeight='normal'>
                Carregando...
          </Text>
            </CardHeader>
            <CardBody mt='8px'>
              <Spinner size='xl' />
            </CardBody>
          </Center>
        </Card >
      </Flex >
    );

  } else {
    return (
      <Flex direction='row' pt={{ base: "10", md: "75px" }}>
        <Card>
          <CardHeader>
            <Heading size='md'>Relatório de recebimento {toMoney(invoice.total)}</Heading>
          </CardHeader>

          <CardBody style={{ display: 'flex', 'flex-direction': "column", gap: "10px" }} >
            <div>
              <Box>
                <Text pt='2' fontSize='sm'>
                  Vencimento: {convertDate(invoice.due_date)}
                </Text>
              </Box>
              <Box>
                <Text pt='2' fontSize='sm'>
                  Pagamento: {convertDate(invoice.payed_at)}
                </Text>
              </Box>
            </div>
            <hr />
            <Box mt="10">
              <Center>
                <Heading size='xs' textTransform='uppercase'>
                  Encaminhamentos
                </Heading>
              </Center>
            </Box>
            <TableContainer>
              <Table mt="6" variant='striped' colorScheme='teal'>
                <Thead>
                  <Tr>
                    <Th>Procedimento</Th>
                    <Th>Prestador</Th>
                    <Th>Paciente</Th>
                    <Th isNumeric>Valor</Th>
                    <Th isNumeric>Comissão</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoice.transfer_forwardings?.map((forward) => {
                    return (
                      <Tr key={forward.id}>
                        <Td>{forward.procedure_provider.procedure.name}</Td>
                        <Td>{forward.procedure_provider.provider.name}</Td>
                        <Td>{forward.patient.name} {forward.patient.last_name}</Td>
                        <Td isNumeric>{toMoney(forward.amount_sell)}</Td>
                        <Td isNumeric>{toMoney(forward.amount_sell / 100 * invoice.percentage)}</Td>
                      </Tr>
                    )
                  })}

                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td>Total</Td>
                    <Td>{toMoney(invoice.total)}</Td>
                  </Tr>
                </Tbody>

              </Table>
            </TableContainer>

          </CardBody>
        </Card>
      </Flex >
    );
  }
}

export default ReportDetail
