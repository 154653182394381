import React from "react";
import { useParams } from "react-router-dom";
import { toMoney, convertCpf } from "../../../utils";
import axiosInstance from "./../../../axios";

function Print() {
  const { id } = useParams();
  const [forwarding, setForwarding] = React.useState({});
  const [hide, setHide] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const print_appointment = () => {
    setHide(true);
    setTimeout(function() {
      print();
      setHide(false);
    }, 900); //wait 2 seconds
  };
  React.useEffect(() => {
    axiosInstance.get(`forwarding/${id}`).then((response) => {
      setForwarding(response.data);
      setIsLoading(false);
      print();
      setHide(false);
    });
  }, []);

  return (
    !isLoading && (
      <div>
        <h1 style={{ textAlign: "center" }}>Guia de Encaminhamento</h1>
        {hide ? null : (
          <button onClick={print_appointment}>Imprimir novamente</button>
        )}
        <h5 style={{ textAlign: "center" }}>
          Número da Guia: {forwarding.code}
        </h5>
        <hr />
        <h3>Clinica</h3>
        <ul>
          <li>
            Endereço:{" "}
            {forwarding.procedures[0].procedure_provider.provider.street}{" "}
            {forwarding.procedures[0].procedure_provider.provider.number},{" "}
            {forwarding.procedures[0].procedure_provider.provider.district}{" "}
            {forwarding.procedures[0].procedure_provider.provider.city}{" "}
            {forwarding.procedures[0].procedure_provider.provider.state}{" "}
            {forwarding.procedures[0].procedure_provider.provider.zip_code}
          </li>
          <li>
            Telefone:{" "}
            {forwarding.procedures[0].procedure_provider.provider.phone1}
          </li>
          <li>
            Telefone 2:{" "}
            {forwarding.procedures[0].procedure_provider.provider.phone2}
          </li>
          <li>
            E-mail: {forwarding.procedures[0].procedure_provider.provider.email}
          </li>
        </ul>
        <h3>Paciente </h3>
        <ul>
          <li>
            Nome: {forwarding.patient.name} {forwarding.patient.last_name}
          </li>
          <li>Celular: {forwarding.patient.cellphone1}</li>
          <li>CPF: {convertCpf(forwarding.patient.cpf)}</li>
        </ul>
        <h3 className="footer-printer">SAC Interclinicas: (41)3442-0000</h3>
        <hr className="footer-printer" />
      </div>
    )
  );
}

export default Print;
