export function toMoney(money) {
  return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(".", ",");
}

export const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const convertDate = (date) => {
  if (!date) {
    return ""
  }
  const dateConverted = new Date(date)
  return dateConverted.toLocaleDateString('pt-br')
}


export function convertCpf(cpf_string) {
  if (!cpf_string) {
    return ""
  }
  const cpf = cpf_string.slice(0, 3) + '.' + cpf_string.slice(3, 6) + "." + cpf_string.slice(6, 9) + "-" + cpf_string.slice(9, 11)
  return cpf
}

export const stateOptions = [
  { value: 'AC', label: 'Acre' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];
