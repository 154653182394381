import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./custom.css"

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Print from "views/Dashboard/Print";
import { GlobalProvider } from './GlobalContext';

const App = () => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('access_token');
    setLoading(false)
    if (!token) {
      if (window.location.pathname !== "/auth/login/") {
        window.location.href = '/auth/login/';
      }
    }
  }, [])

  if (loading) {
    return (
      <h1>Carregando</h1>
    )
  }
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/admin`} component={AdminLayout} />
          <Route path={`/printer/:id`} component={Print} />
          <Route path={`/printer/:id`} component={Print} />

          <Redirect from={`/`} to="/admin/dashboard" />
        </Switch>
      </BrowserRouter>
    </GlobalProvider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
