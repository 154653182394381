import {
  Table,
  Stack,
  Spacer,
  Box,
  Center,
  Tbody,
  Input,
  Text,
  Th,
  InputGroup,
  InputRightAddon,
  Thead,
  Button,
  Tr,
  Icon,
  Td,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

import _ from 'lodash';
import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FaEdit, FaSearch, FaArrowLeft, FaArrowRight, FaPlusCircle } from "react-icons/fa";
import axiosInstance from "../../../axios";
import { openInNewTab, toMoney, convertDate } from "utils"
import { Link } from "react-router-dom";


function ReportList() {
  const textColor = useColorModeValue("gray.700", "white");
  const [invoices, setInvoices] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(null);
  const [prevPage, setPrevPage] = React.useState(null);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    axiosInstance.get('invoice-clinic/', { params: { active: true, limit: 10, search } }).then((response) => {
      setInvoices(response.data.results)
      setNextPage(response.data.next)
      setPrevPage(response.data.previous)
    })
  }, [search])

  const navigate = (url) => {
    axiosInstance.get(url, { params: { active: true, limit: 10, search } }).then((response) => {
      setInvoices(response.data.results)
      setNextPage(response.data.next)
      setPrevPage(response.data.previous)
    }).catch((error) => {
    })
  }

  const handleSearch = _.debounce((e) => {
    setSearch(e);
  }, 500);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader mb='20px' style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", gap: "20px" }}>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Lista de recebimentos
          </Text>
        </CardHeader>
        <hr />
        <CardBody mt='8px'>
          <Table variant='simple' color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                <Th color='gray.400' >
                  Valor
                </Th>
                <Th color='gray.400' >
                  Data vencimento
                </Th>
                <Th color='gray.400' >
                  Data recebido
                </Th>
                <Th color='gray.400' >
                  Detalhes
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoices.map((row) => {
                return (
                  <Tr key={row.id}>
                    <Td>
                      {toMoney(row.total)}
                    </Td>
                    <Td>
                      {convertDate(row.due_date)}
                    </Td>
                    <Td>
                      {convertDate(row.payed_at)}
                    </Td>
                    <Td>
                      <Link to={`relatorios-detalhes/${row.id}`}>
                        <Button >
                          <Icon as={FaSearch} color="gray.400" cursor="pointer" />
                        </Button>
                      </Link>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        <Stack direction='row' mt={"10px"} spacing={4}>
          {prevPage &&
            <Button colorScheme='teal' onClick={() => navigate(prevPage)} variant='outline'>
              <Icon as={FaArrowLeft} cursor="pointer" />
            </Button>
          }
          {nextPage &&
            <Button colorScheme='teal' onClick={() => navigate(nextPage)} variant='outline'>
              <Icon as={FaArrowRight} cursor="pointer" />
            </Button>
          }
        </Stack>
      </Card >
    </Flex >
  );
}

export default ReportList;
