// Chakra Icons
import React from "react";
import { BellIcon, SearchIcon, SunIcon, MoonIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useHistory } from 'react-router-dom';
// Chakra Imports
import {
  Button,
  Flex,
  InputGroup,
  Menu,
  MenuButton,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
// Assets
// Custom Icons
// Custom Components
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import routes from "routes.js";
import { GlobalContext } from "../../GlobalContext";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const userConfig = React.useContext(GlobalContext);

  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    history.push('/auth/signin/');
  }
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <InputGroup
        cursor="pointer"
        bg={inputBg}
        borderRadius="15px"
        w={{
          sm: "128px",
          md: "200px",
        }}
        me={{ sm: "auto", md: "20px" }}
        _focus={{
          borderColor: { mainTeal },
        }}
        _active={{
          borderColor: { mainTeal },
        }}
      >
      </InputGroup>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <p>{userConfig.userConfig.clinic_first_name + " " + userConfig.userConfig.clinic_last_name}</p>
      <Menu>
        {/* <MenuButton ml="16px"> */}
        {/*   <BellIcon color={navbarIcon} w="18px" h="18px" /> */}
        {/* </MenuButton> */}
        <MenuButton onClick={logout} ml="16px">
          Sair
          <ArrowForwardIcon />
        </MenuButton>
      </Menu>

      {/* <Button ml="16px" onClick={toggleColorMode}> */}
      {/*   {colorMode === "light" ? <MoonIcon /> : <SunIcon />} */}
      {/* </Button> */}

    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
