// Requiring lodash library
const _ = require('lodash');

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  Table,
  Text,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon, DeleteIcon } from "@chakra-ui/icons";

// Custom icons
import axiosInstance from "./../../../axios";
import AsyncSelect from "react-select/async";
import State from "react-select";
import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ptbr from "date-fns/locale/pt-BR";

import Card from "components/Card/Card.js";
import { openInNewTab, stateOptions, toMoney } from "utils";

registerLocale("pt-BR", ptbr);

export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [appointmentDate, setAppointmentDate] = React.useState(new Date());
  const [procedures, setProcedures] = React.useState([]);
  const [selectedProcedures, setSelectedProcedures] = React.useState([]);
  const [providerProcedure, setProviderProcedure] = React.useState("");
  const [providerSelected, setProvider] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [patient, setPatient] = React.useState("");
  const [schedulePerson, setSchedulePerson] = React.useState("");
  const [patientForm, setPatientForm] = React.useState({});
  const [procedureSelected, setProcedureSelected] = React.useState("");
  const [total, setTotal] = React.useState(0);

  const toast = useToast();
  const [error, setError] = React.useState({});

  React.useEffect(() =>  {
    if (procedureSelected) {
      axiosInstance
        .get("procedures-provider", {
          params: {
            procedure_name: procedureSelected,
            provider: providerSelected,
            active: true,
            limit: 50,
          },
        })
        .then((response) => {
          setProcedures(response.data.results);
        });
    }
  }, [procedureSelected]);

  React.useEffect(() => {
    if (selectedProcedures.length > 0) {
      const total = selectedProcedures.reduce((acc, procedure) => {
        return acc + Number(procedure.price_sell);
      }, 0);
      setTotal(total);
    }
  }, [selectedProcedures]);

  const handlerProcedureSearch = (procedure) => {
    setProcedureSelected(procedure);
  };

  function savePatient() {
    axiosInstance
      .post("patients/", patientForm)
      .then((res) => {
        onClose();
        toast({
          title: "Sucesso.",
          description: "Paciente salvo com sucesso",
          position: "top-right",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setError(err.response.data);
      });
  }

  function handleInputPatient(value, name) {
    let newValue = patientForm;
    newValue[name] = value;
    setPatientForm(newValue);
  }

  const handleSchedulePerson = (value) => {
    setSchedulePerson(value);
  };

  function handleProviderSelected(procedure) {
    setProvider(procedure.value);
    setProcedureSelected("");
    setProcedures([]);
    setSelectedProcedures([]);
    setProviderProcedure("");
  }

  function handleCpfSelected(input) {
    setPatient(input.value);
  }

  function generateAppointment() {
    setIsLoading(true);
    const data = {
      patient: patient,
      procedures: selectedProcedures.map((procedure) => procedure.id),
      scheduled_person: schedulePerson,
      scheduled_at: appointmentDate.toLocaleString("pt-br"),
    };
    axiosInstance
      .post("forwarding/", data)
      .then((res) => {
        onClose();
        toast({
          title: "Sucesso.",
          description: "Agendamento realizado com sucesso",
          position: "top-right",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setPatientForm({});
        setPatient("");
        setProcedures([]);
        setProvider(null);
        setProviderProcedure("");
        setSchedulePerson("");
        setAppointmentDate(new Date());
        setSelectedProcedures([]);
        openInNewTab(`/printer/${res.data.id}`);
      })
      .catch((err) => {
        toast({
          title: "Erro.",
          description: "Erro ao agendar o atendimento",
          position: "top-right",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
        document.querySelector(".css-qc6sy-singleValue").innerText = "";
      });
  }

  const loadClinicOptions = _.debounce((inputValue, callback) => {
    axiosInstance
      .get("providers/", { params: { name: inputValue, hasprovider: true } })
      .then((response) => {
        const options = response.data.results.map((provider) => ({
          value: provider.id,
          label: provider.name,
        }));
        callback(options);
      });
  }, 200)
  const loadOptionsCpf = _.debounce((inputValue, callback) => {
    axiosInstance
      .get("patients", { params: { cpf: inputValue, limit: 11 } })
      .then((response) => {
        const optionsCpf = response.data.results.map((patient) => ({
          value: patient.id,
          label: `${patient.name} ${patient.last_name}`,
        }));
        callback(optionsCpf);
      });
  }, 200)

  const addProcedure = (procedure) => {
    console.log(selectedProcedures);
    const procedureExists = selectedProcedures.find(
      (item) => item.id === procedure.id
    );
    if (procedureExists) {
      toast({
        title: "Erro.",
        description: "Procedimento já adicionado",
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setSelectedProcedures([...selectedProcedures, procedure]);
  };

  const removeProcedure = (procedure) => {
    const newProcedures = selectedProcedures.filter(
      (item) => item !== procedure
    );
    setSelectedProcedures(newProcedures);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card>
        <Grid mt={4} mb={{ lg: "26px" }}>
          <AsyncSelect
            onChange={(e) => {
              handleProviderSelected(e);
            }}
            defaultOptions={[]}
            noOptionsMessage={() => "Sem resultados"}
            isSearchable
            placeholder="Selecione uma Clínica"
            cacheOptions
            loadOptions={loadClinicOptions}
          />
        </Grid>
        <Grid>
          <Stack spacing={4}>
            <InputGroup>
              <Input
                value={procedureSelected}
                onChange={(e) => handlerProcedureSearch(e.target.value)}
                disabled={providerSelected ? false : true}
                placeholder="Pesquise pelo procedimento"
              />
              <InputRightElement>
                <SearchIcon />
              </InputRightElement>
            </InputGroup>
          </Stack>
        </Grid>
        {procedures.length > 0 && (
          <Grid mt={6} mb={{ lg: "26px" }}>
            <>
              <Box textAlign="center" mb={10}>
                <Text fontSize="2xl">Procedimentos</Text>
              </Box>
              <TableContainer>
                <Table variant="striped" size="sm" colorScheme="whatsapp">
                  <Thead>
                    <Tr>
                      <Th>Procedimento</Th>
                      <Th>Valor</Th>
                      <Th isNumeric>Selecionar</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {procedures.map((procedure) => (
                      <Tr key={procedure.id}>
                        <Td>{procedure.procedure_name}</Td>
                        <Td>{toMoney(procedure.price_sell)}</Td>
                        <Td isNumeric>
                          <IconButton
                            icon={<AddIcon />}
                            onClick={() => addProcedure(procedure)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          </Grid>
        )}
        <Grid mt={6} mb={{ lg: "26px" }}>
          {selectedProcedures.length > 0 && (
            <>
              <Box textAlign="center" mb={10}>
                <Text fontSize="2xl">Selecionados</Text>
              </Box>
              <TableContainer>
                <Table variant="striped" size="sm" colorScheme="teal">
                  <Thead>
                    <Tr>
                      <Th>Procedimento</Th>
                      <Th>Valor</Th>
                      <Th isNumeric>Remover</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedProcedures.map((procedure) => (
                      <Tr key={procedure.id}>
                        <Td>{procedure.procedure_name}</Td>
                        <Td>{toMoney(procedure.price_sell)}</Td>
                        <Td isNumeric>
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => removeProcedure(procedure)}
                          />
                        </Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td></Td>
                      <Td></Td>
                      <Td isNumeric>
                        <Text fontSize="xl"> Total {toMoney(total)}</Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
        {selectedProcedures.length > 0 && (
          <Grid mt={4} mb={{ lg: "26px" }}>
            <AsyncSelect
              onChange={(e) => {
                handleCpfSelected(e);
              }}
              defaultOptions={[]}
              noOptionsMessage={() => (
                <>
                  Sem resultados <br />
                  <Button onClick={onOpen}>Adicionar Paciente</Button>
                </>
              )}
              isSearchable
              placeholder="Pesquisar cliente pelo cpf ..."
              cacheOptions
              loadOptions={loadOptionsCpf}
            />
          </Grid>
        )}
        {patient && (
          <Grid mb={{ lg: "26px" }}>
            <Input
              placeholder="Agendado com"
              value={schedulePerson}
              isInvalid={false}
              id="scheduleperson"
              type="text"
              onChange={(e) => handleSchedulePerson(e.target.value)}
            />
          </Grid>
        )}
        {schedulePerson && (
          <Grid mb={{ lg: "26px" }}>
            <DatePicker
              locale="pt-BR"
              withPortal
              timeCaption="Horário"
              dateFormat="dd/MM/yyyy HH:mm"
              timeIntervals={15}
              showTimeSelect
              minDate={new Date()}
              selected={appointmentDate}
              onChange={(date) => setAppointmentDate(date)}
            />
          </Grid>
        )}
        <Button
          isLoading={isLoading}
          disabled={
            !providerSelected ||
            !selectedProcedures.length ||
            !appointmentDate ||
            !patient ||
            !schedulePerson ||
            isLoading
          }
          loadingText="Gerando guia..."
          colorScheme="teal"
          variant="outline"
          spinnerPlacement="end"
          onClick={generateAppointment}
        >
          Gerar guia
        </Button>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          motionPreset="slideInBottom"
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adicionar Paciente</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Nome</FormLabel>
                <Input
                  isInvalid={error.name}
                  id="nome"
                  type="text"
                  onChange={(e) => handleInputPatient(e.target.value, "name")}
                />
                {error.name?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Sobrenome </FormLabel>
                <Input
                  isInvalid={error.last_name}
                  id="sobre_nome"
                  type="text"
                  onChange={(e) =>
                    handleInputPatient(e.target.value, "last_name")
                  }
                />{" "}
                {error.last_name?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Endereço de email</FormLabel>
                <Input
                  isInvalid={error.email}
                  id="email"
                  type="email"
                  onChange={(e) => handleInputPatient(e.target.value, "email")}
                />
                {error.email?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">CPF </FormLabel>
                <Input
                  isInvalid={error.cpf}
                  id="cpf"
                  type="text"
                  onChange={(e) => handleInputPatient(e.target.value, "cpf")}
                />
                {error.cpf?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">RG </FormLabel>
                <Input
                  isInvalid={error.rg}
                  id="rg"
                  type="text"
                  onChange={(e) => handleInputPatient(e.target.value, "rg")}
                />
                {error.rg?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Data de nascimento</FormLabel>
                <Input
                  isInvalid={error.birth_date}
                  id="data_de_nascimento"
                  type="text"
                  onChange={(e) =>
                    handleInputPatient(e.target.value, "birth_date")
                  }
                />
                {error.birth_date?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Estado</FormLabel>
                <State
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={[{ value: "", label: "Selecione um estado" }]}
                  isSearchable
                  onChange={(e) => handleInputPatient(e.value, "state")}
                  name="color"
                  options={stateOptions}
                />
                <FormLabel mt="4">Cidade</FormLabel>
                <Input
                  isInvalid={error.city}
                  id="cidade"
                  type="text"
                  onChange={(e) => handleInputPatient(e.target.value, "city")}
                />
                {error.city?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Celular</FormLabel>
                <Input
                  isInvalid={error.cellphone1}
                  id="cellphone1"
                  type="text"
                  onChange={(e) =>
                    handleInputPatient(e.target.value, "cellphone1")
                  }
                />
                {error.cellphone1?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Bairro</FormLabel>
                <Input
                  isInvalid={error.district}
                  id="bairro"
                  type="text"
                  onChange={(e) =>
                    handleInputPatient(e.target.value, "district")
                  }
                />
                {error.district?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Logradouro</FormLabel>
                <Input
                  isInvalid={error.street}
                  id="logradouro"
                  type="text"
                  onChange={(e) => handleInputPatient(e.target.value, "street")}
                />
                {error.street?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Numero</FormLabel>
                <Input
                  isInvalid={error.number}
                  id="number"
                  type="text"
                  onChange={(e) => handleInputPatient(e.target.value, "number")}
                />
                {error.street?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
                <FormLabel mt="4">Cep</FormLabel>
                <Input
                  isInvalid={error.cep}
                  id="cep"
                  type="text"
                  onChange={(e) =>
                    handleInputPatient(e.target.value, "zip_code")
                  }
                />
                {error.zip_code?.map((err) => (
                  <FormHelperText key={err}>{err}</FormHelperText>
                ))}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button variant="ghost" onClick={() => savePatient()}>
                Salvar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Card>
    </Flex>
  );
}
