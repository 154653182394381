// import
import Dashboard from "views/Dashboard/Dashboard";
import Forwardings from "views/Dashboard/Forwardings";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import { PatientsList, PatientCreate } from "views/Dashboard/Patients";
import { ReportList, ReportDetail } from "views/Dashboard/Reports";

import {
  HomeIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
} from "components/Icons/Icons";
import { FaUser } from "react-icons/fa";
import { FaMoneyBill } from "react-icons/fa";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/pacientes",
    name: "Pacientes",
    icon: <FaUser color="inherit" />,
    component: PatientsList,
    layout: "/admin",
  },
  {
    path: "/guias",
    name: "Guias",
    icon: <DocumentIcon color="inherit" />,
    component: Forwardings,
    layout: "/admin",
  },
  {
    path: "/relatorios",
    name: "Relatórios",
    icon: <FaMoneyBill color="inherit" />,
    component: ReportList,
    layout: "/admin",
  },
  {
    path: "/relatorios-detalhes/:id",
    name: "Relatórios detalhes",
    hide: true,
    icon: <FaMoneyBill color="inherit" />,
    component: ReportDetail,
    layout: "/admin",
  },
  {
    path: "/pacientes/cadastro",
    name: "Cadastro de Pacientes",
    hide: true,
    icon: <FaUser color="inherit" />,
    component: PatientCreate,
    layout: "/admin",
  },

  {
    name: "ACCOUNT PAGES",
    category: "account",
    hide: true,
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Sign Up",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
