// Chakra imports
import {
  Table,
  Stack,
  Spacer,
  Box,
  Center,
  Tbody,
  Input,
  Text,
  Th,
  InputGroup,
  InputRightAddon,
  Thead,
  Button,
  Tr,
  Icon,
  Td,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

import _ from 'lodash';
import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FaEdit, FaSearch, FaArrowLeft, FaArrowRight, FaPlusCircle } from "react-icons/fa";
import axiosInstance from "../../../axios";
import { openInNewTab, convertCpf } from "utils"
import { Link } from "react-router-dom";


function PatientsList() {
  const textColor = useColorModeValue("gray.700", "white");
  const [patients, setPatients] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(null);
  const [prevPage, setPrevPage] = React.useState(null);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    axiosInstance.get('patients/', { params: { active: true, limit: 10, search } }).then((response) => {
      setPatients(response.data.results)
      setNextPage(response.data.next)
      setPrevPage(response.data.previous)
    })
  }, [search])

  const navigate = (url) => {
    axiosInstance.get(url, { params: { active: true, limit: 10, search } }).then((response) => {
      setPatients(response.data.results)
      setNextPage(response.data.next)
      setPrevPage(response.data.previous)
    }).catch((error) => {
    })
  }

  const handleSearch = _.debounce((e) => {
    setSearch(e);
  }, 500);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader mb='20px' style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", gap: "20px" }}>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Lista de pacientes
          </Text>
          <Spacer />
          <InputGroup size='lg' style={{ maxWidth: "400px" }}>
            <Input placeholder='Pesquisar ...' size='lg' onChange={(e) => handleSearch(e.target.value)} />
            <InputRightAddon children=<Icon as={FaSearch} color="gray.400" /> />
            </InputGroup>
          <Link to="/admin/pacientes/cadastro"><FaPlusCircle color="green" size={"40"} /></Link>
        </CardHeader>
        <hr />
        <CardBody mt='8px'>
          <Table variant='simple' color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                <Th color='gray.400' >
                  Nome
                </Th>
                <Th color='gray.400' >
                  CPF
                </Th>
                <Th color='gray.400' >
                  Telefone
                </Th>
                <Th color='gray.400' >
                  Data de nascimento
                </Th>
                {/*
                <Th color='gray.400' >
                    Editar
                </Th>
                */}
              </Tr>
            </Thead>
            <Tbody>
              {patients.map((row) => {
                return (
                  <Tr key={row.id}>
                    <Td minWidth={{ sm: "250px" }} pl="0px">
                      <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                        {row.name} {row.last_name}
                      </Flex>
                    </Td>
                    <Td>
                      {convertCpf(row.cpf)}
                    </Td>
                    <Td>
                      {row.cellphone1}
                    </Td>
                    <Td>
                      {row.birth_date}
                    </Td>
                    {/*
                    <Td>
                      <Button onClick={() => openInNewTab(`/printer/${row.id}`)} p="0px" bg="transparent">
                        <Icon as={FaEdit} color="gray.400" cursor="pointer" />
                      </Button>
                    </Td>
                    */}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        <Stack direction='row' mt={"10px"} spacing={4}>
          {prevPage &&
            <Button colorScheme='teal' onClick={() => navigate(prevPage)} variant='outline'>
              <Icon as={FaArrowLeft} cursor="pointer" />
            </Button>
          }
          {nextPage &&
            <Button colorScheme='teal' onClick={() => navigate(nextPage)} variant='outline'>
              <Icon as={FaArrowRight} cursor="pointer" />
            </Button>
          }
        </Stack>
      </Card >
    </Flex >
  );
}

export default PatientsList;
