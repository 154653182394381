import React, { useState } from "react";
// Chakra imports

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance from "../../axios";
import { useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  useToast,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/BgSignUp.png";
import { GlobalContext } from "../../GlobalContext";


const schema = yup.object().shape({
  email: yup.string().email("Digite um email válido").required("Digite um email"),
  password: yup.string().required("Insira sua senha"),
});

const ShowError = ({ showError, toast }) => {
  if (!showError) {
    return null
  }
  toast({
    title: 'Erro.',
    description: "Login ou senha incorretos",
    status: 'error',
    position: 'top-right',
    duration: 9000,
    isClosable: true,
  })

  return (

    <p style={{ color: "red" }} className="mt-10 ">Login ou senha incorretos</p>
  )
}

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const userConfig = React.useContext(GlobalContext);

  const toast = useToast()
  const history = useHistory();
  const [showError, setShowError] = useState(false)
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });


  const onSubmit = (data) => {
    setShowError(false)
    axiosInstance.post(`token/`, {
      email: data.email,
      password: data.password,
    })
      .then((res) => {
        localStorage.setItem('access_token', res.data.access);
        localStorage.setItem('refresh_token', res.data.refresh);
        axiosInstance.defaults.headers['Authorization'] =
          'JWT ' + localStorage.getItem('access_token');
        axiosInstance.get(`me`).then((res) => {
          userConfig.saveUserConfig(res.data);
        })
        history.push('/');
        setShowError(false)
        toast.closeAll()
        reset()
      })
      .catch(error => {
        if (error.response.statusText === "Unauthorized") {
          setShowError(true)
        }
      });
  };

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Bem vindo de volta
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Faça seu login para continuar
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl >
                <FormLabel htmlFor='email' ms='4px' fontSize='sm' fontWeight='normal'>
                  Email
                </FormLabel>
                <Input
                  id='email'
                  borderRadius='15px'
                  mb='5px'
                  fontSize='sm'
                  type='text'
                  placeholder='Digite seu email'
                  size='lg'
                  {...register("email")}
                />
                <p style={{ "color": "red" }} className="mt-4">{errors.email?.message}</p>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Senha
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='36px'
                  fontSize='sm'
                  type='password'
                  placeholder='Digite sua senha'
                  size='lg'
                  {...register("password")}
                />
                <Button
                  fontSize='10px'
                  type='submit'
                  bg='teal.300'
                  w='100%'
                  h='45'
                  mb='20px'
                  color='white'
                  mt='20px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}>
                  ENTRAR
                  </Button>
              </FormControl>
              <ShowError showError={showError} toast={toast} />
            </form>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
