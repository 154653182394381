// Chakra imports
import {
  Table,
  Stack,
  Spacer,
  Box,
  Center,
  Tbody,
  Input,
  Text,
  Th,
  InputGroup,
  InputRightAddon,
  Thead,
  Button,
  Tr,
  Icon,
  Td,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

import _ from 'lodash';
import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FaPrint, FaRegCheckSquare, FaWindowClose, FaSearch, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axiosInstance from "../../../axios";
import { openInNewTab } from "utils"


function Fowarginds() {
  const textColor = useColorModeValue("gray.700", "white");
  const [forwardings, setForwardings] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(null);
  const [prevPage, setPrevPage] = React.useState(null);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    axiosInstance.get('forwarding/', { params: { active: true, limit: 10, search } }).then((response) => {
      setForwardings(response.data.results)
      setNextPage(response.data.next)
      setPrevPage(response.data.previous)
    })
  }, [search])

  const navigate = (url) => {
    axiosInstance.get(url, { params: { active: true, limit: 10, search } }).then((response) => {
      setForwardings(response.data.results)
      setNextPage(response.data.next)
      setPrevPage(response.data.previous)
    }).catch((error) => {
    })
  }

  const handleSearch = _.debounce((e) => {
    setSearch(e);
  }, 500);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>

      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader mb='20px'>
          <Flex >
            <Center>
              <Text fontSize='xl' color={textColor} fontWeight='bold'>
                Guias
              </Text>
            </Center>
            <Spacer />
            <Box w='800px' flex='1' >
              <InputGroup size='lg'>
                <Input placeholder='Pesquisar ...' size='lg' onChange={(e) => handleSearch(e.target.value)} />
                <InputRightAddon children=<Icon as={FaSearch} color="gray.400" />/>
              </InputGroup>
            </Box>
          </Flex>
        </CardHeader>

        <hr />
        <CardBody mt='8px'>
          <Table variant='simple' color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                <Th color='gray.400' >
                  Procedimento
                </Th>
                <Th color='gray.400' >
                  Paciente
                </Th>
                <Th color='gray.400' >
                  Clínica
                </Th>
                <Th color='gray.400' >
                  Data agendamento
                </Th>
                <Th color='gray.400' >
                  Realizado
                </Th>
                <Th color='gray.400' >
                  Impressão
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {forwardings.map((row) => {
                return (
                  <Tr key={row.id}>
                    <Td minWidth={{ sm: "250px" }} pl="0px">
                      <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                        <Text
                          fontSize="md"
                          color={textColor}
                          fontWeight="bold"
                          minWidth="100%"
                        >
                          {row.procedure_name}
                        </Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                        {row.patient_name} {row.patient_last_name}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                        {row.provider_name}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                        {new Date(row.scheduled_at).toLocaleString('pt-br')}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="xl" color={textColor} fontWeight="bold" pb=".5rem">
                        {row.confirmed_at ?
                          <Icon as={FaRegCheckSquare} color="green.400" />
                          :
                          <Icon as={FaWindowClose} color="red.400" />
                        }
                      </Text>

                    </Td>
                    <Td>
                      <Button onClick={() => openInNewTab(`/printer/${row.id}`)} p="0px" bg="transparent">
                        <Icon as={FaPrint} color="gray.400" cursor="pointer" />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        <Stack direction='row' mt={"10px"} spacing={4}>
          {prevPage &&
            <Button colorScheme='teal' onClick={() => navigate(prevPage)} variant='outline'>
              <Icon as={FaArrowLeft} cursor="pointer" />
            </Button>
          }
          {nextPage &&
            <Button colorScheme='teal' onClick={() => navigate(nextPage)} variant='outline'>
              <Icon as={FaArrowRight} cursor="pointer" />
            </Button>
          }
        </Stack>

      </Card >
    </Flex >
  );
}

export default Fowarginds;
